<template>
  <header class="header">    
    <div class="logo">
      <div class="novumWeb">
        <div class="Nov">NOVUM</div>
        <div class="Web">Web Solutions</div>
      </div>
    </div>
      <nav class="nav">
        <button @click="toggleMenu" class="menu-btn">
          <span class="bar"></span>
          <span class="bar"></span>
          <span class="bar"></span>
        </button>
        <ul v-if="menuOpen" class="menu">
          <li><a href="#services">Services</a></li>
          <li><a href="#about">About Us</a></li>
          <li><a href="#contact">Contact</a></li>
        </ul>
      </nav>
  </header>
  <router-view/>
  <Footer/>
</template>

<script>
import Footer from './components/Footer.vue';

export default {
  components: { Footer },
  data() {
    return {
      isMenuActive: false,
    };
  },
  methods: {
    toggleMenu() {
      this.isMenuActive = !this.isMenuActive;
    },
    handleLinkClick() {
      this.isMenuActive = false;
    },
  },
}
</script>

<style lang="scss" >
:root {
  --Gray: #252F34;
  --white: #FFFFFF;
  --darkGreen: #0A7557;
  --darkBlue: #172328;
  --lightGreen: #0EA47A;  
  --nicoMoji: 'Nico Moji', 'Courier New', Courier, monospace;
}

@font-face {
  font-family: 'Nico Moji';
  src: url('@/assets/Nico-Moji/nicomoji/NicoMoji-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  font-family: 'Readex Pro', sans-serif;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: var(--darkBlue);
  border-bottom: 2px solid var(--Gray);

  .logo {
    font-family: 'Nico Moji', sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    
    .novumWeb {
      display: flex;
      flex-direction: column;
      align-items: center;
      border-radius: 5px;
      
      .Nov {
        font-size: 2rem;
        color: #FFFFFF;
      }
      
      .Web {
        font-size: 0.88rem;
        color: #0EA47A;
      }
    }
  }

  .menu-btn {
    display: none;
  }
  //   flex-direction: column;
  //   cursor: pointer;
  //   margin-left: auto;
  //   padding-right: 15px;

  //   .bar {
  //     width: 25px;
  //     height: 3px;
  //     background-color: #335C85;
  //     margin: 3px 0;
  //     transition: all 0.3s ease;
  //   }
  // }
  // .menu {
  //   list-style-type: none;
  //   padding: 0;
  // }
}

</style>