<template>
  <div class="main-container">
    <!-- Hero Section -->
    <section class="hero">
      <div class="title">Crafting High-Quality Web Solutions for Your Business</div>
      <p>
        Expert web development, digital marketing, and tailored solutions designed to elevate your online presence, drive business growth, and deliver measurable results. From stunning websites to comprehensive marketing strategies, we provide the tools your business needs to thrive in the digital landscape.
      </p>
      <button class="cta-button" @click="redirectToDeclareInerest">Declare Interest</button>
    </section>

    <!-- Approach Section -->
    <section class="approach">
      <div class="title">Our Approach for a Successful Brand Digitalization</div>
      <div class="approach-cards">
        <div v-for="step in steps" :key="step.title" class="card">
          <div class="stepTitle">{{ step.title }}</div>
          <p>{{ step.description }}</p>
        </div>
      </div>
    </section>

    <!-- Why Choose Us Section -->
    <section class="why-choose-us">
      <div class="title">Why Choose Novum</div>
      <div class="content">
        <div v-for="item in reasons" :key="item.title">
          <div class="itemTitle">{{ item.title }}</div>
          <p>{{ item.description }}</p>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  methods: {
    redirectToDeclareInerest() {
      const formUrl = "https://docs.google.com/forms/d/e/1FAIpQLScDe7Nanup0O2FxqEtmVC8yqpjx6-6NKVYV4JZaDUmdlfhuXg/viewform?usp=sf_link";
      window.open(formUrl, "_blank");
    }
  },
  data() {
    return {
      steps: [
        { title: "Exploration", description: "We identify the client's objectives, market insights, and user needs to lay a solid foundation for the project, ensuring alignment with overall goals." },
        { title: "Foundation", description: "We establish clear project goals, develop strategic plans, and set a creative direction that will guide the entire design process toward successful outcomes." },
        { title: "Brainstorming", description: "We create interactive models to test and validate ideas, gather user feedback, and refine concepts before moving into the detailed design phase." },
        { title: "Design", description: "We craft detailed, user-centric visual designs that meet project goals and user expectations, ensuring a cohesive and engaging user experience." },
        { title: "Development", description: "We translate the final designs into high-quality, functional code, bringing the digital product to life with precision and excellence." },
        { title: "Satisfaction", description: "We strive to exceed client expectations by delivering exceptional results and maintaining open communication, ensuring complete satisfaction and long-term fulfillment with our services." },
      ],
      reasons: [
        { title: "1. Custom Solutions Tailored to Your Business Needs ", description: "At Novum, we don’t believe in one-size-fits-all. Every website and marketing strategy is customized to meet the unique goals and challenges of your business, ensuring you stand out in a crowded digital marketplace." },
        { title: "2. Seamless Integration of Web Development & Marketing", description: "We provide more than just beautiful websites—Novum integrates top-tier web development with powerful digital marketing strategies, ensuring your online presence not only looks great but performs even better." },
        { title: "3. Ongoing Support for Continuous Growth", description: "Our partnership doesn’t end at launch. We offer ongoing maintenance, optimization, and marketing services to keep your website secure, fast, and evolving as your business grows." },
        { title: "4. Results-Driven Approach with Proven Expertise", description: "Our team of experienced developers and digital marketers is focused on delivering measurable results, from improving site performance to boosting your brand’s online visibility, driving traffic, and increasing conversions." },
      ],
    };
  }
};
</script>

<style lang="scss" scoped>
.main-container {
  color: var(--white);
  background-color: var(--Gray);

  /* Hero Section Styles */
  .hero {
    text-align: left;
    padding: 3rem 10rem;
    background-color: var(--darkBlue);

    .title {
      font-weight: 200;
      font-size: 2rem;
      text-decoration: underline var(--lightGreen) 2px;
      margin-bottom: 2rem;
    }

    p {
      margin-right: 40%;
      margin-bottom: 2rem;
    }

    .cta-button {
      background-color: var(--lightGreen);
      color: var(--white);
      font-size: 1.2rem;
      border-radius: 8px;
      border: none;
      padding: 0.5rem 1rem;
      cursor: pointer;
      font-family: 'Readex Pro';
      font-weight: 200;
      box-shadow: 0px 0px 5px var(--lightGreen);

      &:hover {
        box-shadow: 0px 0px 15px var(--lightGreen);
      }
    }
  }

  /* Approach Section Styles */
  .approach {
    text-align: center;
    padding: 3rem 10rem;
    color: var(--white);

    .title {
      font-weight: 200;
      font-size: 2rem;
      margin-bottom: 2rem;
    }

    .approach-cards {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
      margin: 0 15%;
      gap: 1.5rem;

      .card {
        background-color: var(--darkBlue);
        width: auto;
        padding: 2rem;
        border-radius: 8px;
        color: var(--white);

        &:hover {
          box-shadow: 0px 0px 8px var(--lightGreen);
        }

        .stepTitle {
          font-size: 1.4rem;
          text-align: center;
        }

        p {
          font-weight: 200;
          font-size: 0.9rem;
          margin-top: 1rem;
        }
      }
    }
  }

  /* Why Choose Us Section Styles */
  .why-choose-us {
    background-color: var(--darkBlue);
    color: #c4c4c4;
    padding: 2rem 10rem;

    .title {
      text-align: center;
      color: var(--white);
      font-size: 2.5rem;
      padding: 2rem;
    }

    .content {
      padding: 2rem 0;

      div {
        padding: 1rem 0;

        .itemTitle {
          color: var(--lightGreen);
          font-size: 2rem;
          padding: 0;
        }

        p {
          margin-right: 60%;
        }
      }
    }
  }
}

/* Mobile Styles */
@media (max-width: 768px) {
  .main-container {
    padding: 0; /* Overall padding for mobile */

    /* Hero Section Styles */
    .hero {
      padding: 2rem; /* Adjust padding for mobile */
      text-align: center; /* Center align text */

      .title {
        font-size: 1.5rem; /* Smaller title for mobile */
        margin-bottom: 1rem;
      }

      p {
        margin: 0; /* Remove right margin */
        margin-bottom: 1rem; /* Adjust bottom margin */
      }

      .cta-button {
        width: 100%; /* Full-width button on mobile */
        font-size: 1rem; /* Smaller font size */
        padding: 0.5rem; /* Adjust padding */
      }
    }

    /* Approach Section Styles */
    .approach {
      padding: 2rem; /* Adjust padding for mobile */

      .title {
        font-size: 1.5rem; /* Smaller title for mobile */
        margin-bottom: 1rem;
      }

      .approach-cards {
        margin: 0; /* Remove margin */
        padding: 0; /* Remove padding */

        .card {
          padding: 1.5rem; /* Adjust card padding */
        }
      }
    }

    /* Why Choose Us Section Styles */
    .why-choose-us {
      padding: 2rem; /* Adjust padding for mobile */

      .title {
        font-size: 2rem; /* Smaller title for mobile */
      }

      .content {
        div {
          padding: 0.5rem 0; /* Adjust padding */
          .itemTitle {
            font-size: 1.5rem; /* Smaller item title */
          }

          p {
            margin-right: 0; /* Remove right margin */
          }
        }
      }
    }
  }
}
</style>
