<template>
  <footer>
    <div class="footer-container">
      <div class="novumWeb">
        <div class="Nov">NOVUM</div>
        <div class="Web">Web Solutions</div>
      </div>
      <div class="footer-sections">
        <div class="footer-section">
          <div class="section-title">Socials</div>
          <ul>
            <li><a href="#about" aria-label="Instagram">Instagram</a></li>
            <li><a href="#about" aria-label="Twitter">Twitter</a></li>
            <li><a href="#about" aria-label="Email">Email</a></li>
            <li><a href="#about" aria-label="LinkedIn">LinkedIn</a></li>
          </ul>
        </div>
        <div class="footer-section">
          <div class="section-title">Services</div>
          <ul>
            <li><a href="#services" aria-label="Service 1">UI/UX Design</a></li>
            <li><a href="#services" aria-label="Service 2">Web Development</a></li>
            <li><a href="#services" aria-label="Service 3">Mobile Development</a></li>
            <li><a href="#services" aria-label="Service 4">Search Engine Optimization</a></li>
          </ul>
        </div>
        <div class="footer-section">
          <div class="section-title">Company</div>
          <ul>
            <li><a href="#company" aria-label="About Us">About Us</a></li>
            <li><a href="#company" aria-label="Careers">Careers</a></li>
            <li><a href="#company" aria-label="Contact">Contact</a></li>
            <li><a href="#company" aria-label="Blog">Blog</a></li>
          </ul>
        </div>
      </div>
    </div>
    <p class="copyright">© Copyright All rights reserved</p>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
};
</script>

<!-- Scoped styles to apply only to this component -->
<style lang="scss" scoped>
footer {
  background-color: var(--darkBlue);
  box-shadow: inset 0 4px 4px rgba(0, 0, 0, 0.25);
  padding: 20px;
  color: var(--white);
  font-family: var(--nicoMoji);

  .footer-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    .novumWeb {
      align-self: start;
      display: flex;
      flex-direction: column;
      align-items: center;
      border-radius: 5px;
      margin: 1rem 0 3rem 8rem;

      .Nov {
        font-size: 2.5rem;
        color: var(--white);
      }

      .Web {
        font-size: 1.1rem;
        color: #0EA47A;
      }
    }

    .footer-sections {
      display: flex;
      justify-content: space-between;
      width: 100%;
      max-width: 1200px;
      margin: 0 2rem;

      .footer-section {
        margin: 0 10px;
        text-align: left;

        .section-title {
          font-size: 1.5rem;
          margin-bottom: 10px;
        }

        ul {
          list-style-type: none;
          padding: 0;

          li {
            margin: 5px 0;
            font-family: 'Readex Pro', sans-serif;
            font-weight: 100;

            a {
              color: var(--white);
              text-decoration: none;
            }

            a:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }

  .copyright {
    text-align: center;
    font-size: 0.8rem;
    margin-top: 2rem;
  }
}

/* Mobile Styles */
@media (max-width: 768px) {
  .footer-container {
    padding: 10px;

    .novumWeb {
      align-self: center;
    }

    .footer-sections {
      flex-direction: column; /* Stack sections vertically */
      align-items: center; /* Center align sections */
      width: 100%; /* Full width */

      .footer-section {
        text-align: center; /* Center text for mobile */
        margin: 1rem 0; /* Add vertical spacing */
        width: 100%; /* Full width for each section */
      }

      .section-title {
        font-size: 1.2rem; /* Adjust font size for titles */
      }
    }
  }

  .copyright {
    font-size: 0.7rem; /* Smaller copyright font size */
  }
}
</style>
